import { Injectable } from '@angular/core';
import * as GooglePhoneLib from 'google-libphonenumber';

@Injectable({
  providedIn: 'root',
})
export class GoogleUtilsService {
  public util: GooglePhoneLib.PhoneNumberUtil;

  constructor() {
    this.util = GooglePhoneLib.PhoneNumberUtil.getInstance();
  }

  public parsePhone(phone: string): GooglePhoneLib.PhoneNumber {
    return this.util.parseAndKeepRawInput(phone);
  }
}
